export const StatusTexts = {
  0: "Bekliyor",
  1: "Tamamlandı",
  2: "İptal Edildi",
  default: "",
};

export const statusOptions = [
  { value: 0, label: "Bekliyor" },
  { value: 1, label: "Tamamlandı" },
  { value: 2, label: "İptal Edildi" },
];
